.btn {
    cursor: pointer;

}

.btn, .btn-like {
    /*box-shadow: 5px 5px 3px #80808000, -5px 15px 3px #80808000;*/
    transition: box-shadow 0.2s, transform 0.2s, color 0.1s, background-color 0.1s;
    cursor: pointer;
}

.btn:hover:not([disabled]):not(.btn-link), .btn-like:hover {
    box-shadow: 5px 5px 22px #80808030, -5px 15px 22px #80808029;

}

.btn .hover {
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s, background-color 0.2s;
    z-index: 10;
    border-radius: 5px;
    transform-origin: 0 50%;
    color: var(--color-black);
    padding-left: 10px;
    padding-right: 10px;
}

.btn:hover .hover {
    opacity: 1;
    background-color: var(--color-white);
    transform: translateX(120%);
}

.btn .hover.copied {
    opacity: 1;
    transform: translateX(120%) scale(1.2);
    background-color: var(--color-white);

}

.btn .hover .hidden {
    display: none;
}

.btn .hover.copied .hidden {
    display: unset;
}

.internal_link, .internal_link:hover {
    text-decoration: none;
    color: inherit;
}

.variable-root.dark .external_link, .variable-root.dark .as_external_link {
    color: #fff;
}

.variable-root.dark .external_link:hover, .variable-root.dark .as_external_link:hover {
    color: #adacff;
}


.variable-root.light .external_link, .variable-root.light .as_external_link {
    color: #0006ff;
}

.variable-root.light .external_link:hover, .variable-root.light .as_external_link:hover {
    color: #001659;
}
