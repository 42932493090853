.field input.invalid {
    border: 1px solid red;
    box-shadow: 0 0 4px red;
}

.field label, .field_labelish {
    font-size: 80%;
    font-weight: bold;
    opacity: 1;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
}
.field_labelish_bigger {
    font-size: 90%;
    font-weight: bold;
    opacity: 0.7;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
}
.field_division {
    font-size: 110%;
    font-weight: bold;
    opacity: 0.8;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
}

.fade-color {
    opacity: 0.4;
}

.fade-color:hover, .fade-color:focus {
    opacity: 1;
}
