.list-item {
  padding: 20px;
  border-radius: 14px;
  transition: box-shadow 0.5s, color 0.5s, background-color 0.5s;
  box-shadow: 0px 0px 10px var(--color-shadow-neu-right),
  -0px -0px 10px var(--color-shadow-neu-right);
  background-color: var(--color-white);
  color: var(--color-black);
  border: 0px solid transparent;

  &.clickable {
    border: 1px solid transparent;
  }
}

.list-item, .badge {
  &.gradient_bg {
    background: rgb(59, 105, 120);
    background: linear-gradient(175deg, rgba(59, 105, 120, 1) 0%, rgba(32, 64, 81, 1) 100%);
    color: white;
  }
}

.list-item, .badge {
  &.gradient_bg_amazon {
    background: rgb(59, 105, 120);
    background: linear-gradient(175deg, #e88a39 0%, rgba(32, 64, 81, 1) 200%);
    color: white;
  }
}

.list-item, .badge {
  &.gradient_bg_profit {
    background: rgb(59, 105, 120);
    background: linear-gradient(175deg, #edffea 0%, rgba(32, 64, 81, 1) 200%);
    color: black;
  }
}

.list-item-shadow {
  box-shadow: 0px 0px 10px var(--color-shadow-neu-right),
  -0px -0px 10px var(--color-shadow-neu-right);
  border-radius: 14px;
}

.list-item.deleted {
  background-color: var(--color-light-red);
}

.list-item.compact {
  padding: 10px;
  border-radius: 4px;
}

.neu_shadows {
  box-shadow: 3px 3px 6px var(--color-shadow-neu-right),
  -3px -3px 6px var(--color-shadow-neu-left);
}


.list-item:hover {

}


.clickable {
  cursor: pointer;
}


.list-item.active, .list-item.clickable:hover {
  border: 1px solid green;
  box-shadow: 3px 3px 6px var(--color-shadow-neu-right),
  -3px -3px 6px var(--color-shadow-neu-left);

}
